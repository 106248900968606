@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  body {
    color: white;
    user-select: none;
  }
  